import { FansFundMeDashboardScreen } from '@fans-fund-me/storybook';
import React, { useEffect, useState } from 'react';
import useAuth from '../../contexts/UseAuth';
import currencies from '../../utilities/currencies.json';

export const Dashboard = (): React.ReactElement => {
  const auth = useAuth();

  const [, setUserCurrency] = useState('');

  // const updateUserCurrency = (currency: string) => {
  //   setUserCurrency(currency);
  //   localStorage.setItem('ffm_currency', currency);
  // };

  useEffect(() => {
    const currency = localStorage.getItem('ffm_currency');

    if (currency !== null) {
      setUserCurrency(currency);
    }
  }, [setUserCurrency]);

  return (
    <FansFundMeDashboardScreen
      navbarItems={[]}
      authUser={
        auth.authUser !== undefined
          ? auth.authUser
          : {
              username: '',
              currency: '',
              isStripeSetupComplete: false,
              notAcceptingPaymentsReasons: [],
            }
      }
      acceptedCurrencies={currencies}
      events={[]}
      totalReceivedToday={0}
      totalReceivedMonth={0}
      totalReceivedAllTime={0}
      cookieBannerIsVisible={auth.cookies === null}
      onClickAcceptCookies={auth.acceptCookies}
      onClickAcceptNecessaryCookies={auth.acceptNecessaryCookies}
    />
  );
};
