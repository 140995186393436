import React, { useState } from 'react';
import { FansFundMeFrequentlyAskedQuestionsScreen } from '@fans-fund-me/storybook';
import useAuth from '../../contexts/UseAuth';
import categories from './faq-categories.json';
import faqs from './faqs.json';
import { useLocation } from 'react-router-dom';
import currencies from '../../utilities/currencies.json';

export const FrequentlyAskedQuestions = () => {
  const auth = useAuth();
  const location = useLocation();

  const [userCurrency, setUserCurrency] = useState(
    auth.currency !== null
      ? auth.currency
      : localStorage.getItem('ffm_currency') !== null
      ? localStorage.getItem('ffm_currency')
      : 'USD'
  );

  const updateUserCurrency = (currency: string) => {
    setUserCurrency(currency);
    localStorage.setItem('ffm_currency', currency);
  };

  const pathParts = location.pathname.split('/');

  let category = '';
  let question = '';

  if (pathParts.length >= 3) {
    category = pathParts[2];
  }

  if (pathParts.length >= 4) {
    question = pathParts[3];
  }

  return (
    <FansFundMeFrequentlyAskedQuestionsScreen
      navbarItems={[]}
      authUser={auth.authUser}
      acceptedCurrencies={currencies}
      userCurrency={userCurrency ?? 'USD'}
      setUserCurrency={updateUserCurrency}
      categories={categories}
      faqs={faqs}
      category={category}
      question={question}
      cookieBannerIsVisible={auth.cookies === null}
      onClickAcceptCookies={auth.acceptCookies}
      onClickAcceptNecessaryCookies={auth.acceptNecessaryCookies}
    />
  );
};
