import { FansFundMeHomepageScreen } from '@fans-fund-me/storybook';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useAuth from '../../contexts/UseAuth';
import currencies from '../../utilities/currencies.json';
import validate from '../../utilities/validation';
import axios from '../../api/axios';

export const Home = (): React.ReactElement => {
  const API_ENDPOINT: string = process.env.REACT_APP_API_ENDPOINT_PROFILE ?? '';

  const auth = useAuth();
  const navigate = useNavigate();

  const [heroValue, setHeroValue] = useState('');
  const [isLoadingHero, setIsLoadingHero] = useState(false);
  const [heroError, setHeroError] = useState('');
  const [lastCallValue, setLastCallValue] = useState('');
  const [isLoadingLastCall, setIsLoadingLastCall] = useState(false);
  const [lastCallError, setLastCallError] = useState('');

  const [userCurrency, setUserCurrency] = useState(
    auth.currency !== null ? auth.currency : 'USD'
  );

  const updateUserCurrency = (currency: string) => {
    setUserCurrency(currency);
    localStorage.setItem('ffm_currency', currency);
  };

  useEffect(() => {
    setHeroError('');
    setLastCallError('');
    localStorage.removeItem('ffm_register_username');
  }, [heroValue]);

  const onClickHero = () => {
    setIsLoadingHero(true);

    if (auth.authUser !== undefined) {
      setIsLoadingHero(false);
      setHeroError('You need to be logged out in order to create an account.');
      return;
    }

    if (validate('username', heroValue.toLowerCase(), setHeroError)) {
      axios
        .get(`${API_ENDPOINT}/username/${heroValue.toLowerCase()}`)
        .then(() => {
          localStorage.setItem(
            'ffm_register_username',
            heroValue.toLowerCase()
          );
          navigate('/register/country');
        })
        .catch((apiError) => {
          if (
            apiError.response.status === 409 ||
            apiError.response.status === 400
          ) {
            setHeroError(
              apiError.response.data.fields?.find(
                (field: { fieldName: string; message: string }) =>
                  field.fieldName === 'username'
              )?.message
            );
          } else {
            setHeroError(
              'There was an error processing your request. Please try again.'
            );
          }
        });
    }

    setIsLoadingHero(false);
  };

  const onClickLastCall = () => {
    setIsLoadingLastCall(true);

    if (auth.authUser !== undefined) {
      setIsLoadingLastCall(false);
      setLastCallError(
        'You need to be logged out in order to create an account.'
      );
      return;
    }

    if (validate('username', lastCallValue.toLowerCase(), setLastCallError)) {
      axios
        .get(`${API_ENDPOINT}/username/${lastCallValue.toLowerCase()}`)
        .then(() => {
          localStorage.setItem(
            'ffm_register_username',
            lastCallValue.toLowerCase()
          );
          navigate('/register/country');
        })
        .catch((apiError) => {
          if (
            apiError.response.status === 409 ||
            apiError.response.status === 400
          ) {
            setLastCallError(
              apiError.response.data.fields?.find(
                (field: { fieldName: string; message: string }) =>
                  field.fieldName === 'username'
              )?.message
            );
          } else {
            setLastCallError(
              'There was an error processing your request. Please try again.'
            );
          }
        });
    }

    setIsLoadingLastCall(false);
  };

  return (
    <FansFundMeHomepageScreen
      navbarItems={[]}
      authUser={auth.authUser}
      acceptedCurrencies={currencies}
      userCurrency={userCurrency}
      setUserCurrency={updateUserCurrency}
      heroValue={heroValue}
      setHeroValue={setHeroValue}
      isLoadingHero={isLoadingHero}
      heroError={heroError}
      onClickHero={onClickHero}
      lastCallValue={lastCallValue}
      setLastCallValue={setLastCallValue}
      isLoadingLastCall={isLoadingLastCall}
      lastCallError={lastCallError}
      onClickLastCall={onClickLastCall}
      cookieBannerIsVisible={auth.cookies === null}
      onClickAcceptCookies={auth.acceptCookies}
      onClickAcceptNecessaryCookies={auth.acceptNecessaryCookies}
    />
  );
};
