import { useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import useAuth from '../contexts/UseAuth';
import ReactGA from 'react-ga4';

export const Root = () => {
  const auth = useAuth();
  const location = useLocation();
  const { pathname } = useLocation();

  useEffect(() => {
    if (auth.cookies === 'all') {
      ReactGA.send({
        hitType: 'pageview',
        page: location.pathname + location.search,
      });
    }
  }, [location, auth.cookies]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return <Outlet />;
};
