import axios from 'axios';
import { AuthContextInterface } from '../contexts/AuthContext';

const PROFILE_REGEX = /\/profile\/profile\/\w+\?byUsername=true/;
const PROFILE_REQUEST_REGEX = /\/profile\/profile\/\w+/;
const LOGIN_REGEX = /\/login/;

function AxiosInterceptors(auth: AuthContextInterface) {
  axios.interceptors.request.use((config) => {
    return config;
  });

  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    function (error) {
      if (
        (error.response?.status >= 500 && error.response?.status < 600) ||
        (error.response?.status === 400 &&
          error.response.data.errorType !== 'INVALID_USER_DATA') ||
        (error.request.responseURL.match(PROFILE_REGEX) !== null &&
          error.response?.status === 404) ||
        (error.request.responseURL.match(PROFILE_REQUEST_REGEX) !== null &&
          Object.keys(JSON.parse(error.config.data)).every(
            (v) => v === 'currency'
          )) ||
        (error.response?.status === 403 &&
          error.request.responseURL.match(LOGIN_REGEX) === null) ||
        error.request.responseURL === 'https://ipapi.co/currency/'
      ) {
        axios
          .post(`${process.env.REACT_APP_API_ENDPOINT_ERRORS}`, {
            userDetails: {
              userId: auth.userID,
            },
            request: {
              requestLine: {
                method: error.config.method.toUpperCase(),
                uri: error.config.url,
              },
              requestBody: error.response.config.data,
            },
            response: {
              responseLine: {
                statusCode: error.response.status,
                statusMessage: error.response.statusText,
              },
              responseBody: JSON.stringify(error.response.data),
            },
          })
          .then(() => {
            if (error.response?.status === 403) {
              auth.logout();
            }
          })
          .catch(() => {
            if (error.response?.status === 403) {
              auth.logout();
            }
          });
      } else {
        if (error.response?.status === 403) {
          auth.logout();
        }
      }

      return Promise.reject(error);
    }
  );
}

export default axios;
export { AxiosInterceptors };
