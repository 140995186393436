import React, { useEffect, useState } from 'react';
import { FansFundMePaymentCompleteScreen } from '@fans-fund-me/storybook';
import { useNavigate } from 'react-router-dom';
import { useStripe } from '@stripe/react-stripe-js';
import { ItemProps as Item, ItemProps } from '../profile/ItemProps';
import useAuth from '../../contexts/UseAuth';
import currencies from '../../utilities/currencies.json';

interface PaymentCompleteProps {
  /**
   * The Stripe secret
   */
  clientSecret: string;

  /**
   * The id of the creator who has listed the item
   */
  creatorId: string;

  /**
   * The name of the creator who has listed the item
   */
  creatorName: string;

  /**
   * The currency of the creator who has listed the item
   */
  creatorCurrency: string;

  /**
   * The profile picture of the creator who has listed the item
   */
  creatorProfilePicture: string;

  /**
   * The currency of the user
   */
  userCurrency: string;

  /**
   * Function to update the user's currency
   */
  setUserCurrency: (value: string) => void;

  /**
   * The wishlist items to display
   */
  wishlistItems: ItemProps[];
}

export const PaymentComplete = ({
  clientSecret,
  creatorId,
  creatorName,
  creatorCurrency,
  creatorProfilePicture,
  userCurrency,
  setUserCurrency,
  wishlistItems,
}: PaymentCompleteProps) => {
  const navigate = useNavigate();
  const auth = useAuth();
  const stripe = useStripe();

  const [status, setStatus] = useState<
    'processing' | 'requires_payment_method' | 'succeeded' | 'loading' | 'error'
  >('loading');

  useEffect(() => {
    if (!stripe) {
      return;
    }

    if (clientSecret !== null) {
      stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
        if (paymentIntent !== undefined) {
          // Inspect the PaymentIntent `status` to indicate the status of the payment
          // to your customer.
          //
          // Some payment methods will [immediately succeed or fail][0] upon
          // confirmation, while others will first enter a `processing` state.
          //
          // [0]: https://stripe.com/docs/payments/payment-methods#payment-notification
          switch (paymentIntent.status) {
            case 'succeeded':
              // setMessage('Success! Payment received.');
              setStatus(paymentIntent.status);

              localStorage.removeItem('ffm_checkout_user');
              localStorage.removeItem('ffm_checkout_item');
              localStorage.removeItem('ffm_checkout_surprise_gift');
              localStorage.removeItem('ffm_checkout_surprise_gift_amount');
              localStorage.removeItem('ffm_checkout_surprise_gift_id');
              localStorage.removeItem('ffm_checkout_surprise_gift_message');
              break;

            case 'processing':
              setStatus(paymentIntent.status);
              break;

            case 'requires_payment_method':
              setStatus(paymentIntent.status);
              break;

            default:
              setStatus('error');
              break;
          }
        }
      });
    }
  }, [stripe, clientSecret]);

  const checkout = (item: Item) => {
    localStorage.setItem('ffm_checkout_user', creatorId);
    localStorage.setItem('ffm_checkout_item', item.id);
    navigate('/checkout');
  };

  return (
    <FansFundMePaymentCompleteScreen
      navbarItems={[]}
      authUser={auth.authUser}
      acceptedCurrencies={currencies}
      creatorName={creatorName}
      creatorProfilePicture={creatorProfilePicture}
      userCurrency={userCurrency}
      setUserCurrency={setUserCurrency}
      status={status}
      currency={creatorCurrency}
      wishlistItems={wishlistItems}
      handleClickCheckout={() => navigate('/checkout')}
      onClickBackToProfile={() =>
        navigate(`/${localStorage.getItem('ffm_checkout_username')}`)
      }
      onClickWishlistItem={checkout}
      cookieBannerIsVisible={auth.cookies === null}
      onClickAcceptCookies={auth.acceptCookies}
      onClickAcceptNecessaryCookies={auth.acceptNecessaryCookies}
    />
  );
};
