import React, { useCallback, useEffect, useState } from 'react';
import { FansFundMeLoginScreen } from '@fans-fund-me/storybook';
import useAuth from '../../../contexts/UseAuth';
import { useNavigate } from 'react-router-dom';
import axios from '../../../api/axios';
import validate from '../../../utilities/validation';

export const Login = () => {
  const auth = useAuth();
  const navigate = useNavigate();

  const [emailAddress, setEmailAddress] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setError('');
  }, [emailAddress, password]);

  const handleLogin = useCallback(() => {
    if (
      validate('email address', emailAddress, setError, 'email') &&
      validate('password', password, setError)
    ) {
      setIsLoading(true);

      axios
        .put(`${process.env.REACT_APP_API_ENDPOINT_AUTHENTICATION}/user`, {
          email: emailAddress,
          password: password,
        })
        .then((response) => {
          axios
            .get(
              `${process.env.REACT_APP_API_ENDPOINT_PROFILE}/profile/${response.data.userId}`
            )
            .then((profileResponse) => {
              setIsLoading(false);

              let isAcceptingPayments = 'false';

              if (
                profileResponse.data.acceptingPayments &&
                profileResponse.data.acceptingPaymentsOptIn
              ) {
                isAcceptingPayments = 'true';
              }

              auth.login(
                response.data.tokenString,
                response.data.userId,
                profileResponse.data.username,
                profileResponse.data.currency,
                isAcceptingPayments,
                profileResponse.data.notAcceptingPaymentsReasons
              );
              navigate(`/${profileResponse.data.username}`);
            });
        })
        .catch((err) => {
          if (err.response.status === 403) {
            setError(
              'This combination of email and password cannot be matched with an account.'
            );

            setIsLoading(false);
          }
        });
    }
  }, [auth, navigate, emailAddress, password]);

  useEffect(() => {
    const listener = (event: KeyboardEvent) => {
      if (event.code === 'Enter' || event.code === 'NumpadEnter') {
        event.preventDefault();
        handleLogin();
      }
    };

    document.addEventListener('keydown', listener);
    return () => {
      document.removeEventListener('keydown', listener);
    };
  }, [handleLogin]);

  return (
    <FansFundMeLoginScreen
      emailAddress={emailAddress}
      setEmailAddress={setEmailAddress}
      password={password}
      setPassword={setPassword}
      onClickLogin={handleLogin}
      isLoading={isLoading}
      error={error}
      cookieBannerIsVisible={auth.cookies === null}
      onClickAcceptCookies={auth.acceptCookies}
      onClickAcceptNecessaryCookies={auth.acceptNecessaryCookies}
    />
  );
};
