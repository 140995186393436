import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { PaymentComplete } from './PaymentComplete';
import axios from '../../api/axios';
import useAuth from '../../contexts/UseAuth';

export const PaymentCompleteHOC = () => {
  const navigate = useNavigate();
  const auth = useAuth();

  const [creatorId, setCreatorId] = useState('');
  const [creatorName, setCreatorName] = useState('');
  const [creatorProfilePicture, setCreatorProfilePicture] = useState('');
  const [creatorCurrency, setCreatorCurrency] = useState('');
  const [userCurrency, setUserCurrency] = useState(
    auth.currency !== null
      ? auth.currency
      : localStorage.getItem('ffm_currency') !== null
      ? localStorage.getItem('ffm_currency')
      : 'USD'
  );
  const [wishlistItems, setWishlistItems] = useState([]);

  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY!);

  const clientSecret = new URLSearchParams(window.location.search).get(
    'payment_intent_client_secret'
  );

  const updateUserCurrency = (currency: string) => {
    setUserCurrency(currency);
    localStorage.setItem('ffm_currency', currency);
  };

  useEffect(() => {
    const userID = localStorage.getItem('ffm_checkout_user');
    const redirectUsername = localStorage.getItem(
      'ffm_payment_complete_redirect_to'
    );

    if (userID === null && redirectUsername !== null) {
      localStorage.removeItem('ffm_payment_complete_redirect_to');
      navigate(`/${redirectUsername}`);
    }

    axios
      .get(`${process.env.REACT_APP_API_ENDPOINT_PROFILE}/profile/${userID}`)
      .then((response) => {
        setCreatorId(response.data.userId);
        setCreatorName(response.data.displayName);
        setCreatorProfilePicture(response.data.profilePic);
        setCreatorCurrency(response.data.currency);

        localStorage.setItem(
          'ffm_payment_complete_redirect_to',
          response.data.username
        );
      })
      .catch((err) => console.log(err));

    axios
      .get(
        `${process.env.REACT_APP_API_ENDPOINT_PROFILE}/profile/${userID}/wishlist?currency=${userCurrency}`
      )
      .then((response) => {
        setWishlistItems(response.data.items);
      })
      .catch((err) => console.log(err));
  }, [navigate, auth.currency, userCurrency]);

  return (
    <Elements
      stripe={stripePromise}
      options={{
        clientSecret: clientSecret!,
        fonts: [
          {
            cssSrc: 'https://fonts.googleapis.com/css?family=Work+Sans',
          },
        ],
        appearance: {
          theme: 'night',
          variables: {
            colorPrimary: '#0570de',
            colorBackground: '#2f3032',
            spacingUnit: '0.25rem',
            fontFamily: 'Work Sans',
            borderRadius: '0.25rem',
            spacingGridColumn: '2rem',
            spacingGridRow: '2rem',
            spacingTab: '2rem',
          },
          rules: {
            '.Input': {
              borderColor: '#2f3032',
              boxShadow: '0',
            },
            '.Block': {
              backgroundColor: '#171717',
              border: '#171717',
              boxShadow: '0',
              paddingTop: '1rem',
              paddingBottom: '1.5rem',
            },
          },
        },
      }}
    >
      <PaymentComplete
        clientSecret={clientSecret!}
        creatorId={creatorId}
        creatorName={creatorName}
        creatorProfilePicture={creatorProfilePicture}
        creatorCurrency={creatorCurrency}
        userCurrency={userCurrency!}
        setUserCurrency={updateUserCurrency}
        wishlistItems={wishlistItems}
      />
    </Elements>
  );
};
