import React, { useState } from 'react';
import { FansFundMeRegisterStripeScreen } from '@fans-fund-me/storybook';
import { useNavigate } from 'react-router-dom';
import axios from '../../../api/axios';
import useAuth from '../../../contexts/UseAuth';

export const RegisterStripe = () => {
  const navigate = useNavigate();
  const auth = useAuth();

  const [isLoading, setIsLoading] = useState(false);

  const handleRegisterStripe = () => {
    if (auth.token !== null) {
      axios
        .post(
          `${process.env.REACT_APP_API_ENDPOINT_PAYMENTS}/account/`,
          {
            refreshUrl: `${process.env.REACT_APP_STRIPE_REDIRECT}/${auth.username}`,
            returnUrl: `${process.env.REACT_APP_STRIPE_REDIRECT}/${auth.username}`,
          },
          {
            headers: { Authorization: `Bearer ${auth.token}` },
          }
        )
        .then((response) => {
          setIsLoading(true);
          location.href = response.data.url;
        });
    } else {
      navigate('/login');
    }
  };

  return (
    <FansFundMeRegisterStripeScreen
      isLoading={isLoading}
      onClickRegisterStripe={handleRegisterStripe}
      cookieBannerIsVisible={auth.cookies === null}
      onClickAcceptCookies={auth.acceptCookies}
      onClickAcceptNecessaryCookies={auth.acceptNecessaryCookies}
    />
  );
};
