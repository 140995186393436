import axios from './axios';

const getWishlistByUsername = (
  username: string,
  currency: string | undefined
) => {
  let request = `${process.env.REACT_APP_API_ENDPOINT_PROFILE}/profile/${username}/wishlist?byUsername=true`;

  if (currency !== undefined) {
    request += `&currency=${currency}`;
  }

  return axios.get(request);
};

export { getWishlistByUsername };
